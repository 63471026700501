import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/layout/Base';

import Heading from './Heading';

interface Props {
  data: {
    yaschaMounk: Core.ImageAttributes;
  };
}

const FeatureContent: React.FC<Props> = (props) => {
  const { yaschaMounk } = props.data;

  return (
    <Layout title="Confronting a Crisis">
      <Heading />
      <Core.ContentWrapper>
        <Core.ContentSection>
          <Core.LargeText fontStyle="sans" inverted>
            New faculty member Yascha Mounk is on a mission to strengthen
            liberal democracy. With a dual appointment at the John Hopkins
            University’s Agora Institute and by teaching classes at Homewood, he
            is well-positioned to bridge departments and schools as he shares
            his compelling ideas across the university.
          </Core.LargeText>
        </Core.ContentSection>
        <Core.ContentSection
          marginaliaStackedBottom
          marginalia={
            <>
              <Core.FullWidthImage image={yaschaMounk} alt="Yascha Mounk" />
              <Core.BoldText>Yascha Mounk</Core.BoldText>
              <br />
              <Core.Caption>
                Senior Fellow, SNF Agora Institute & Associate Professor of the
                Practice, Johns Hopkins University School of Advanced
                International Studies (SAIS)
              </Core.Caption>
            </>
          }
        >
          <p>
            <Core.LeadIn>Yascha Mounk</Core.LeadIn> is not your average
            international affairs professor. Since earning his PhD from Harvard
            University four years ago, the German-American scholar has become a
            leading expert on the fragility of liberal democracy. The buzz
            around his research has landed him 55,000 Twitter followers, his own
            Slate podcast, a steady stream of speaking engagements, and bylines
            everywhere from The New Yorker to The Economist. At 37, he’s written
            three books that have been translated into 10 languages. And he
            recently joined Johns Hopkins after accepting a dual position:
            associate professor of the practice of International Affairs at
            Johns Hopkins SAIS and senior fellow at the SNF Agora Institute at
            Homewood.
          </p>
          <p>
            Mounk’s message is a troubling one: The rise of authoritarian
            populism is threatening the stability of democratic countries,
            including the United States, and it’s our responsibility to do
            something about it.
          </p>
          <p>
            His work first entered the spotlight in 2015, by way of a New York
            Times op-ed penned with fellow researcher Roberto Foa. The pair
            analyzed data from the World Values Survey, which studies
            representative samples of citizens in almost 100 countries, and
            found that 72 percent of Americans born before World War II thought
            living in a democracy was critically important — rating it a maximum
            10 out of 10. Fast forward to Americans born since the 1980s, and
            the number dropped to less than 30 percent. The survey data also
            revealed that the number of Americans who approved of the idea of
            “having the army rule” jumped from one in 15 in 1995 to one in six
            in 2014.
          </p>
          <p>
            Mounk and Foa say their findings indicate a growing disillusionment
            with democracy not just among Americans, but in many European
            countries as well. They published their research in the Journal of
            Democracy in 2016, but the ideas were met with some skepticism at
            the time. The notion that people all over the West were more willing
            to entertain authoritarian alternatives was a hard pill to swallow.
          </p>
          <p>
            “When I started talking and writing about this, people thought my
            arguments were interesting but a bit off the beaten path, to put it
            politely,” recalls Mounk. “They said, ‘You’re making an interesting
            argument here, but come on. I’m sure you don’t really believe it.
            You’re being a little bit foolish.’
          </p>
        </Core.ContentSection>
        <Core.BigQuote>
          When we saw the Brexit referendum winning, Donald Trump being elected,
          and Rodrigo Duterte winning in the Philippines ... I went from being a
          bit of a provocateur to somebody who could help explain some of the
          things that were going on.
        </Core.BigQuote>
        <Core.ContentSection>
          <p>
            “Then when we saw the Brexit referendum winning, Donald Trump being
            elected, and Rodrigo Duterte winning in the Philippines … I went
            from being a bit of a provocateur to somebody who could help explain
            some of the things that were going on,” he says.
          </p>
          <p>
            That recognition has led to a number of high-profile positions for
            Mounk, including senior fellow at the German Marshall Fund, senior
            adviser at Protect Democracy, and contributing editor at The
            Atlantic. In 2017, former British Prime Minister Tony Blair hired
            him to oversee a Renewing the Centre program at his eponymous
            Institute for Global Change, which is dedicated to helping political
            leaders defend liberal democracy from populism. He left that role at
            the end of 2018 to join Johns Hopkins SAIS and the SNF Agora
            Institute.
          </p>
          <p>
            Mounk is the first scholar to be formally affiliated with the SNF
            Agora Institute, which launched in 2017 with a $150 million gift
            from the Stavros Niarchos Foundation. Its mission is to serve as an
            academic and public forum dedicated to strengthening democracy by
            examining political polarization and developing new ways to support
            the open exchange of ideas. Renowned architect Renzo Piano is
            designing a building to house the institute on the Homewood campus,
            with a completion date set for 2022. Agora’s leadership plans to
            invite faculty members from various departments across Johns Hopkins
            to contribute to its intellectual pursuits.
          </p>
          <p>
            “The idea of building an institute that tries to understand what’s
            going on with politics and democracy today by drawing on political
            scientists, sociologists, historians, economists — and actually
            communicating some of the findings to a wider audience and engaging
            the public — that fits my interests and my instincts much better
            than any traditional academic department,” says Mounk.
          </p>
          <p>
            He adds, “At academic institutions, there is always the risk of
            creating different silos where departments don&rsquo;t speak to one
            another. One of the wonderful things about the people who have been
            hired at Homewood and SAIS is that we can make connections between
            different units of Johns Hopkins, where all scholars do different
            work.”
          </p>
        </Core.ContentSection>
        <Core.BigQuote>
          The idea of building an institute that tries to understand what’s
          going on with politics and democracy today by drawing on political
          scientists, sociologists, historians, economists — and actually
          communicating some of the findings to a wider audience and engaging
          the public — that fits my interests and my instincts much better than
          any traditional academic department.
        </Core.BigQuote>
        <Core.ContentSection>
          <p>
            Last spring, graduate students had their first opportunity to sign
            up for Mounk’s class at Johns Hopkins SAIS, Democracy in Crisis. The
            course covers the main themes of his research, including whether
            populism poses an existential threat to liberal democracy and what
            strategies might allow political actors to push back. Students at
            Homewood also had the chance to take an undergraduate version of the
            course, called Populism and Politics.
          </p>
          <p>
            “I found the students to be phenomenally talented, engaged, and from
            a broad range of personal and professional backgrounds,” says Mounk.
            “When we talked about Turkey or Venezuela, there were students from
            those countries, which obviously made some of the questions seem a
            lot less abstract.”
          </p>
          <p>
            When asked how he finds the time to add teaching to his already busy
            schedule of podcasting, lecturing, writing op-eds, and working on
            his fourth book, he says being in the classroom helps to fuel his
            other endeavors.
          </p>
          <p>
            “Research and teaching complement each other. When I’m thinking
            about how to translate complicated ideas in political science to a
            wider audience, it helps to have experience teaching that material
            and seeing where students may struggle to understand arguments,” he
            says. “Sometimes students have great ideas that make me think about
            my work in a different context.”
          </p>
        </Core.ContentSection>
        <Core.BigQuote>
          By having debates about the state of our democracy and involving a
          broad audience in those debates, I believe SAIS and the Agora
          Institute can play an important role.
        </Core.BigQuote>
        <Core.ContentSection>
          <p>
            Mounk was born in 1982 in Munich to a family who had fled Poland
            decades earlier. His first book, Stranger in My Own Country, is a
            memoir reflecting on the Jewish experience in modern-day Germany.
            Growing up, he says, the only people who were considered “truly
            German” were those whose families had been living in the region for
            generations. But in the United States, he discovered those rules no
            longer applied.
          </p>
          <p>
            “The principle that people from any part of the world can come here
            and be truly American really appealed to me,” says Mounk, who
            applied to become a U.S. citizen in 2017.
          </p>
          <p>
            Today he is largely focused on how to strengthen liberal democracy.
            His most recent book, The People Vs. Democracy: Why Our Freedom Is
            in Danger and How to Save It, proposes a number of concrete
            solutions, including political activism and policy changes. He has
            also given a popular TED talk and written a number of op-eds on the
            topic.
          </p>
          <p>
            He says part of the answer is to increase living standards for
            average citizens, many of whom have become disillusioned with the
            American dream and the political system that comes with it. Research
            shows more than 90 percent of Americans born in 1940 were earning
            more than their parents had at the same stage of their lives, while
            only half of people born in the early 1980s are earning more than
            their parents did.
          </p>
          <p>
            According to Mounk, that shift has alienated voters from traditional
            political institutions and played a key role in helping Donald Trump
            win the presidency. To recover citizens’ loyalty, he suggests we
            change our political institutions to be more responsive to the views
            and interests of ordinary people.
          </p>
          <p>
            “By having debates about the state of our democracy and involving a
            broad audience in those debates, I believe SAIS and the Agora
            Institute can play an important role,” he says.
          </p>
          <p> </p>
          <p>
            The other element will be educating young people and informing
            citizens about populist threats that are already affecting other
            parts of the world.
          </p>
          <p>
            “The mission of turning students into citizens who have the tools to
            change the world is more urgent than people recognize,” says Mounk.
            “Making people more aware of how brittle our freedom and
            self-determination are — how easy it is to lose those things to a
            demagogue — can help empower them to defend those institutions from
            people who would like to undermine them.”
          </p>
          <p>
            Mounk has already found a fertile ground for this mission. “The
            community of scholars here at SAIS, the Agora Institute, and
            Homewood is just excellent, and people have been incredibly
            welcoming,” he says. “The leadership here has a real vision for what
            a 21st-century university should look like and what a university
            presence in Washington should look like. It feels like a lot of
            exciting changes are happening.”
          </p>
        </Core.ContentSection>
        <Core.Share />
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    yaschaMounk: file(
      relativePath: { regex: $directory }
      name: { eq: "yascha-mounk" }
    ) {
      ...MarginaliaImage
    }
  }
`;

export default FeatureContent;
